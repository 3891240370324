import React from "react";
import Profile from "../../components/events/index";
import Navbar from "../../components/navbar/Navbar";
import EventRoutes from "../../routes/eventRoutes";

const Index = () => {
  return (
    <>
      <Navbar />
      <Profile>
        <EventRoutes />
      </Profile>
    </>
  );
};

export default Index;
