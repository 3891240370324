import React from "react";
import "./Navbar.css";
import { FaUserCircle } from "react-icons/fa";
import logo from "../../assets/logo.png";

const Navbar = () => {
  return (
    <>
      <nav>
        <div className="logo">
          <img src={logo} alt="logo" height={40} width={170} />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
