import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../components/auth/login";
import EventProfile from "../pages/event/index";

import Loader from "../components/loader";
import { ProtectedAuthRoute, ProtectedRoute } from "./protectedRoute";

const EventList = lazy(() => import("../pages/user/eventList"));
const RegisterEvent = lazy(() => import("../pages/user/registerEvent"));
const EditSubmission = lazy(() => import("../pages/user/EditSubmission"));

const Index = () => {
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/event*"
              element={
                <ProtectedRoute>
                  <EventProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/event-list"
              element={
                <ProtectedAuthRoute>
                  <EventList />
                </ProtectedAuthRoute>
              }
            />

            <Route path="/register/:_id" element={<RegisterEvent />} />
            <Route path="/submissions/:_id" element={<EditSubmission />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
};

export default Index;
